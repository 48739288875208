




































import Vue from "vue";
export default Vue.extend({
	props: {
		start: Date,
		end: Date
	},
	data() {
		return {
			content: ""
		};
	},
	mounted() {
		(this.$refs.startInput as HTMLDivElement).focus();
		addEventListener("keydown", e => {
			if (e.keyCode == 27) this.$emit("isUpdated", false);
		});
	},
	methods: {
		create() {
			if (this.start && this.end) {
				this.$store.commit("pushLoading", {
					name: "CALENDAR",
					message: "캘린더 생성하는 중"
				});
				this.$store
					.dispatch("CALENDAR", {
						content: this.content,
						start: this.start,
						end: this.end
					})
					.then(calendar => {
						this.$store.commit("clearLoading", "CALENDAR");
						this.$emit("isUpdated", false);
						this.content = "";
					})
					.catch(err => console.log(err));
			}
		}
	},
	computed: {}
});
