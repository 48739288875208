


























































import Vue from "vue";
import CalendarBoxCreatePopup from "../../components/Community/CalendarBoxCreatePopup.vue";

export default Vue.extend({
	components: {
		CalendarBoxCreatePopup
	},
	data() {
		return {
			year: 0,
			month: 0,
			currentYear: 0,
			currentMonth: 0,
			currentDay: 0,
			startDay: new Date() as Date,
			endDay: new Date() as Date,
			startPointX: 0,
			startPointY: 0,
			endPointX: 0,
			endPointY: 0,
			isClick: false,

			calendarLeft: 0,
			calendarTop: 0,
			calendarItemWidth: 0,
			calendarItemHeight: 0,

			selectFilter: [] as boolean[],
			lineData: [] as any[],
			tmpLineData: [] as any[],
			colorIndex: 0,
			colors: ["#ff4475", "#538fff", "#ff9a01"],

			isCreatePopup: false,

			content: "",
			start: new Date() as Date,
			end: new Date() as Date,

			calendars: []
		};
	},
	mounted() {
		let now = new Date();
		this.currentYear = now.getFullYear();
		this.currentMonth = now.getMonth();
		this.currentDay = now.getDate();

		this.startDay = new Date(this.year, this.month, 1); //현재달의 첫째 날
		this.endDay = new Date(this.year, this.month, 0); //현재 달의 마지막 날
		addEventListener("resize", () => {
			this.getSize();
		});
		this.getSize();
		this.reload();
		this.calendarReload(this.currentYear, this.currentMonth);
	},
	methods: {
		pMonth() {
			this.month++;
			if (this.month >= 12) {
				this.year++;
				this.month = 0;
			}
			this.allReload();
		},
		mMonth() {
			this.month--;
			if (this.month < 0) {
				this.year--;
				this.month = 11;
			}
			this.allReload();
		},
		allReload() {
			this.calendarReload(this.year, this.month);
			this.reload();
		},
		calendarReload(year: number, month: number) {
			this.year = year;
			this.month = month;

			this.startDay = new Date(this.year, this.month, 1); //현재달의 첫째 날
			this.endDay = new Date(this.year, this.month + 1, 0); //현재 달의 마지막 날
		},
		reload() {
			this.isCreatePopup = false;
			this.$store.commit("pushLoading", {
				name: "GET_CLUB_CALENDAR",
				message: "캘린더 불러오는 중"
			});
			this.$store.dispatch("GET_CLUB_CALENDAR").then(calendars => {
				this.lineData = [];
				this.tmpLineData = [];
				this.$store.commit("clearLoading", "GET_CLUB_CALENDAR");
				calendars.forEach((calendar: any) => {
					this.calendars = calendars;
					let startDate = new Date(calendar.start);
					if (
						this.year == startDate.getFullYear() &&
						this.month == startDate.getMonth()
					) {
						let start =
							startDate.getDate() + this.startDay.getDay();
						let endDate = new Date(calendar.end);
						let end = endDate.getDate() + this.startDay.getDay();
						this.drawLine(
							(start - 1) % 7,
							Math.floor((start - 1) / 7),
							end % 7,
							Math.floor((end - 1) / 7),
							false,
							calendar
						);
					}
				});
			});
		},
		getSize() {
			let calendar = (this.$refs
				.calendar as HTMLDivElement).getBoundingClientRect();

			this.calendarLeft = calendar.left;
			this.calendarTop = calendar.top;

			let item = (this.$refs.calendarItem as HTMLDivElement[])[0];

			this.calendarItemWidth = item.clientWidth;
			this.calendarItemHeight = item.clientHeight;
		},
		onClick(e: MouseEvent | TouchEvent) {
			if (!this.isCreatePopup) {
				this.getSize();
				this.startPointX =
					((e as MouseEvent).clientX ||
						(e as TouchEvent).touches[0].clientX) -
					this.calendarLeft;
				this.startPointY =
					((e as MouseEvent).clientY ||
						(e as TouchEvent).touches[0].clientY) -
					this.calendarTop;
				let position = this.getPosition(
					this.startPointX,
					this.startPointY
				);
				this.isClick = true;
			}
		},
		onClickUp(e: MouseEvent) {
			if (!this.isCreatePopup) {
				this.isClick = false;
				this.isCreatePopup = true;
				let startPosition = this.getPosition(
					this.startPointX,
					this.startPointY
				);
				let endPosition = this.getPosition(
					this.endPointX,
					this.endPointY
				);
				this.start = new Date(
					this.year,
					this.month,
					this.getDays[startPosition[1] * 7 + startPosition[0]]
				);
				this.end = new Date(
					this.year,
					this.month,
					this.getDays[endPosition[1] * 7 + endPosition[0]]
				);
			}
		},
		onClickDrag(e: MouseEvent | TouchEvent) {
			if (this.isClick) {
				this.endPointX =
					((e as MouseEvent).clientX ||
						(e as TouchEvent).touches[0].clientX) -
					this.calendarLeft;
				this.endPointY =
					((e as MouseEvent).clientY ||
						(e as TouchEvent).touches[0].clientY) -
					this.calendarTop;
				let startPosition = this.getPosition(
					this.startPointX,
					this.startPointY
				);
				let endPosition = this.getPosition(
					this.endPointX,
					this.endPointY
				);

				this.tmpLineData = [];
				this.drawLine(
					startPosition[0],
					startPosition[1],
					endPosition[0] + 1,
					endPosition[1],
					true
				);
			}
		},
		getPosition(x: number, y: number) {
			return [
				Math.floor(x / this.calendarItemWidth),
				Math.floor(y / this.calendarItemHeight)
			];
		},
		drawLine(
			startX: number,
			startY: number,
			endX: number,
			endY: number,
			tmpMode: boolean = false,
			content: string = ""
		) {
			let lineHeight = [0, 0, 0, 0, 0];
			for (let i = startY; i <= endY; i++) {
				let startJ = startY == i ? startX : 0;
				for (let j = 0; j < (i == endY ? endX : 7); j++)
					if (this.lineData[i * 7 + j]) lineHeight[i]++;
			}
			if (tmpMode) {
				for (let i = startY; i <= endY; i++) {
					let startJ = startY == i ? startX : 0;
					if (!this.tmpLineData[i * 7 + startJ])
						this.tmpLineData[i * 7 + startJ] = [];
					this.tmpLineData[i * 7 + startJ].push({
						width:
							i == endY
								? endX - startJ
								: 7 - (startY == i ? startJ : 0),
						height: lineHeight[i],
						color: this.colors[this.colorIndex]
					});
				}
			} else {
				for (let i = startY; i <= endY; i++) {
					let startJ = startY == i ? startX : 0;
					if (!this.lineData[i * 7 + startJ])
						this.lineData[i * 7 + startJ] = [];
					this.lineData[i * 7 + startJ].push({
						width:
							i == endY
								? endX - startJ
								: 7 - (startY == i ? startJ : 0),
						height: lineHeight[i],
						color: this.colors[this.colorIndex],
						content
					});
				}
				this.colorIndex = (this.colorIndex + 1) % 3;
			}
		},
		removeCalendar(id: any) {
			this.$store.commit("pushLoading", {
				name: "CALENDAR_DELETE",
				message: "캘린더 삭제하는 중"
			});
			this.$store
				.dispatch("CALENDAR_DELETE", {
					_id: id
				})
				.then(calendar => {
					this.$store.commit("clearLoading", "CALENDAR_DELETE");
					this.reload();
				})
				.catch(err => console.log(err));
		}
	},
	computed: {
		getDays(): any[] {
			let arr = [...new Array(this.endDay.getDate() + 1).keys()];
			if (this.startDay.getDay() == 0) arr.shift();
			for (let i = 0; i < this.startDay.getDay() - 1; i++) {
				arr.unshift(0);
			}
			return arr;
		}
	}
});
